const $sectionsEvents = document.querySelectorAll('.section-events');

if ($sectionsEvents.length) {
    $sectionsEvents.forEach((block) => {
        const toggle = block.querySelector('#event-load-more');
        const postsPerPage = toggle.getAttribute('data-posts-per-page');
        const wrapper = block.querySelector('.section-events__wrapper_posts');

        toggle.addEventListener('click', async (e) => {
            e.preventDefault();
            const activeTab = block.querySelector(
                '.section-events__tab.active'
            );
            let posts = wrapper.querySelectorAll('.post-item-wrapper');
            const formData = new FormData();
            formData.append('action', 'events_loadmore');
            formData.append('offset', posts.length);
            formData.append('tabID', activeTab.getAttribute('id'));
            formData.append('posts-per-page', postsPerPage);
            const RESPONSE = await fetch(window.var_from_php.ajax_url, {
                method: 'POST',
                body: formData,
            });
            const POSTS = await RESPONSE.json();
            if (POSTS.success) {
                const countPosts = toggle.getAttribute('data-count-posts');
                wrapper.innerHTML += POSTS.data;
                posts = wrapper.querySelectorAll('.post-item-wrapper');
                if (countPosts <= posts.length) {
                    toggle.classList.add('hidden');
                }
            }
        });

        const tabs = block.querySelectorAll('.section-events__tab');
        tabs.forEach((tab) => {
            tab.addEventListener('click', async (e) => {
                e.preventDefault();
                const tabID = tab.getAttribute('id');
                let posts = wrapper.querySelectorAll('.post-item-wrapper');
                const formData = new FormData();
                formData.append('action', 'events_tabs');
                formData.append('tabID', tabID);
                const RESPONSE = await fetch(window.var_from_php.ajax_url, {
                    method: 'POST',
                    body: formData,
                });
                const POSTS = await RESPONSE.json();
                if (POSTS.success) {
                    const newCountPosts = POSTS.data.count_posts;
                    toggle.setAttribute('data-count-posts', newCountPosts);
                    [...tabs].forEach((el) => {
                        el.classList.remove('active');
                    });
                    tab.classList.add('active');

                    wrapper.innerHTML = '';
                    wrapper.innerHTML = POSTS.data.events;
                    posts = wrapper.querySelectorAll('.post-item-wrapper');
                    if (newCountPosts <= posts.length) {
                        toggle.classList.add('hidden');
                    } else {
                        toggle.classList.remove('hidden');
                    }
                }
            });
        });
    });
}
